<template>
  <div class="info-page">
    <van-nav-bar title="风速风向仪数据" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="glo-content">
        <div>
            <div v-if="loading">
                <van-loading type="spinner" vertical>加载中</van-loading>
            </div>
            <div v-else>
                <van-collapse v-model="activeNames" accordion>
                    <van-collapse-item title="风速风向仪数据">
                        <van-cell title="设备编号" :value="form.devCode" />
                        <van-cell title="设备类型" value="风速风向仪" />
                        <van-cell title="更新时间" :value="form.devTime" />
                        <van-cell title="环境风力" :value="form.windforce+'级'" />
                        <van-cell title="环境风速" :value="form.windspeed+'m/s'" />
                        <van-cell title="环境风向" :value="form.winddir" />
                        <van-cell title="运行时间" :value="form.runTime+'s'" />
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        activeNames:0,
        bridgeCode:'',
        loading:true,
        form:{
            windspeed:'',
            windforce:'',
            winddir:'...',
            devTime:'',
            runTime:'',
            devCode:''
        }
    }
  },
  methods:{
      onClickLeft(){
        this.$router.go(-1);//返回上一层
      },
      // 获取设备传感器信息
      getData(){
        const bridgeCode = '510321001';
        this.$api.SLOPE.getEnv({
            bridgeCode:bridgeCode
        }).then( d=> {
            this.form.windspeed = d.windspeed;
            this.form.windforce = d.windforce;
            // this.form.windangle = d.windangle;
            this.form.winddir = this.format(d.winddir);
            this.form.devTime= d.devTime;
            this.form.runTime=d.runTime;
            this.form.devCode = d.devId;
            this.loading = false;
        })
      },
      // 格式化风向
      format(d){
        let text = '';
            d == '0' ? text='北风' :
            d == '1' ? text='东北风' :
            d == '2' ? text='东风' :
            d == '3' ? text='东南风' :
            d == '4' ? text='南风' :
            d == '5' ? text='西南风' :
            d == '6' ? text='西风': text='西北风' ;
        return text;
       }
  },
  mounted(){
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
      this.getData();
  },
  beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
}
}
</script>
<style lang="scss" scoped>
.van-cell__title{text-align: left;}
.van-cell__value {
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;
    display: inherit;
}
.van-loading--vertical {
    margin: 50% auto;
}
</style>